<template>
  <slick
    :options="slickOptions"
    class="listing-slider mfp-gallery-container margin-bottom-0"
  >
    <template v-for="(gallery, index) in data">
      <div :key="index" class="box">
        <img :src="gallery.route" class="image" alt="image" />
      </div>
    </template>
  </slick>
</template>

<script>
  import Slick from 'vue-slick'
  export default {
    props: ['data'],
    components: {
      Slick,
    },
    computed: {
      slickOptions() {
        return {
          dots: true,
          infinite: true,
          centerMode: false,
          centerPadding: '0px',
          slidesToShow: 4,
          responsive: [
            {
              breakpoint: 1499,
              settings: {
                centerPadding: '0px',
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                centerPadding: '0px',
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 991,
              settings: {
                centerPadding: '0px',
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 575,
              settings: {
                centerPadding: '0px',
                slidesToShow: 1,
              },
            },
          ],
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .slick-slide .item {
    display: block;
  }
  .box {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .image {
    width: 100%;
  }
  @supports (object-fit: contain) {
    .mfp-gallery {
      object-fit: contain;
      object-position: center center;
    }
  }
</style>
